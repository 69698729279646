<template>
    <div>
        <CRow>
            <CCol md="12"><!--(configurarPerfiles)?'7':'12'" :lg="(configurarPerfiles)?'7':'12'-->
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Asignación perfil a usuarios</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol md="12">
                                <CButton size="sm" class="mb-2 mr-2 btn-class" @click="configurarPerfiles=!configurarPerfiles">
                                    <CIcon name="cil-user"/> Administrar Perfiles
                                </CButton>

                                <CButton size="sm" class="mb-2 mr-2 btn-class" style="width:auto" @click="nuevoUsuario = !nuevoUsuario">
                                    <CIcon name="cil-user"/> {{(nuevoUsuario)?"Cerrar Creación":"Nuevo Usuario"}}
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow v-show="configurarPerfiles">
                            <CCol><!-- :md="(configurarPerfiles)?'4':'0'" :lg="(configurarPerfiles)?'4':'0'" v-show="configurarPerfiles"-->
                                <CCard accent-color="info">
                                    <CCardHeader>
                                        Configuración Perfiles
                                        <div class="card-header-actions">
                                            <CLink href="#" class="card-header-action btn-close" v-on:click="configurarPerfiles = false">
                                                <CIcon name="cil-x-circle"/>
                                            </CLink>
                                        </div>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CRow v-show="!crearPerfilesPorEmpresa">
                                            <CCol md="12">
                                                <CButton type="submit" size="sm" class="float-left mb-2 mr-2 btn-class"  @click="nuevoPerfil = !nuevoPerfil">
                                                    Nuevo Perfil
                                                </CButton>
                                                <CButton type="submit" size="sm" class="float-left mb-2 mr-2 btn-class" @click="crearPerfilesPorEmpresa = !crearPerfilesPorEmpresa">
                                                    Crear un perfil por cada empresa
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                        <CRow v-show="crearPerfilesPorEmpresa">
                                            <CCol md="12">
                                                <CRow>
                                                    <CCol md="5">
                                                        ¿Desea crear un perfil por cada empresa?
                                                    </CCol>
                                                    <CCol md="7">
                                                        <multiselect class="m-0 d-inline-block" 
                                                            v-model="tipoBDSelected"
                                                            deselect-label="" 
                                                            label="nombre" 
                                                            placeholder="Base de Datos" 
                                                            select-label=""
                                                            selectedLabel=""
                                                            :custom-label="customNameTipoBD"
                                                            :options="tipoBD" 
                                                            :searchable="true"
                                                            :loading="isLoadingTipoBD"
                                                            >
                                                        </multiselect>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol md="5">
                                                    <CButton color="success" size="sm" class="mr-1 mb-2" @click="crearPerfilesPorEmpresas" :disabled="tipoBDSelected == null">
                                                        <CIcon name="cil-check-circle"/>  Crear
                                                    </CButton>
                                                    <CButton color="danger" size="sm" class="mr-1 mb-2" @click="crearPerfilesPorEmpresa = false">
                                                        <CIcon name="cil-x-circle"/> Cancelar
                                                    </CButton>
                                                    </CCol>
                                                </CRow>

                                            </CCol>
                                        </CRow>
                                        <CRow v-show="nuevoPerfil">
                                            <CCol md="10">
                                                <CInput
                                                    v-model="perfilNuevo.nombrePerfil"
                                                    label="Nombre Perfil"
                                                    type="text"
                                                    horizontal
                                                    placeholder="Nombre Perfil"
                                                />
                                            </CCol>
                                            <CCol sm="2">
                                                <CButton type="submit" class="mb-2 btn-class"  @click="agregarNuevoPerfil">
                                                    Crear
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol>
                                                <CAlert :show.sync="tiempoAlertaCrear" closeButton v-show="mostrarEstadoMensaje" :color="estadoMensajeNuevoPerfil">
                                                    {{mensajeNuevoPerfil}}
                                                </CAlert>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="12">
                                                <CDataTable
                                                    :hover="true"
                                                    :striped="false"
                                                    :bordered="true"
                                                    :small="true"
                                                    :fixed="true"
                                                    :items="perfiles"
                                                    :fields="columnsPerfiles"
                                                    :items-per-page="10"
                                                    pagination
                                                    style="max-height:auto; overflow: auto;"
                                                >

                                                    <template #no-items-view>
                                                        <center>no hay Perfiles</center>
                                                    </template>
                                                    <td
                                                        slot="nombrePerfil"
                                                        slot-scope="{item}"
                                                        style="vertical-align:middle;">
                                                        <span v-show="!item.editar">
                                                            {{item.nombrePerfil}}
                                                        </span>
                                                        <span v-show="item.editar">
                                                            <CInput
                                                                v-model="item.nombrePerfil"
                                                                type="text"
                                                                horizontal
                                                                placeholder="Nombre Perfil"
                                                                size="sm"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td
                                                        slot="acciones"
                                                        slot-scope="{item}"
                                                        style="vertical-align:middle;"
                                                    >
                                                    <CButtonToolbar aria-label="Toolbar with button groups" >
                                                            <CButtonGroup class="mx-1" v-show="item.editar">
                                                                <CButton color="success" size="sm"
                                                                    @click="actualizarPerfil(item)">
                                                                    <CIcon name="cil-check-circle"/> Aceptar
                                                                </CButton>
                                                                <CButton color="danger" size="sm"
                                                                    @click="item.editar=false">
                                                                    <CIcon name="cil-x-circle"/> Cancelar
                                                                </CButton>
                                                            </CButtonGroup>

                                                            <CButtonGroup class="mx-1" v-show="!item.editar">
                                                                <CButton color="warning" size="sm"
                                                                    @click="item.editar=true">
                                                                    <CIcon name="cil-pencil"/> Editar
                                                                </CButton>
                                                                <CButton color="danger" size="sm" @click="eliminarPerfil(item)"><CIcon name="cil-x-circle"/> Eliminar</CButton>
                                                            </CButtonGroup>
                                                        </CButtonToolbar>
                                                    </td>
                                                    
                                                </CDataTable>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                    <CCardFooter>
                                        
                                    </CCardFooter>
                                </CCard>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="3">
                                <CAlert :show.sync="tiempoAlertaAsignar" closeButton v-show="mostrarEstadoMensajeAsignar" :color="estadoMensajeAsignar">
                                    {{mensajeAsignar}} 
                                </CAlert>
                            </CCol>
                        </CRow>
                        <CRow v-show="nuevoUsuario">
                            <CCol sm="0" md="2"></CCol>
                            <CCol sm="12" md="8">
                                <CrearUsuario :crudPrincipal="this" :perfiles="perfiles" />
                            </CCol>
                            <CCol sm="0" md="2"></CCol>
                        </CRow>
                        <CRow class="mb-2">
                            <CCol md="12">
                                <a-table
                                    :columns="columnsUsuarios" 
                                    :data-source="usuarios"
                                    :pagination="{ pageSize: 30 }"
                                    :scroll="{ y: 270 }"
                                    :locale="locale"
                                    :loading="isLoadingUsuarios"
                                    class="components-table-demo-nested m-1" 
                                    size="small" 
                                    style="overflow:auto;">

                                    <span slot="rutUsuario" slot-scope="usuario">
                                        {{usuario.rut}}
                                    </span>

                                    <span slot="nombreUsuario"
                                        slot-scope="usuario">
                                        {{usuario.nombreUsuario}} {{usuario.apellidoUsuario}}
                                    </span>

                                    <span
                                        slot="perfil" 
                                        slot-scope="perfil, record"
                                    >
                                        <multiselect class="selectBase"
                                            v-model="record.perfilAsignado"
                                            deselect-label=""
                                            label="nombrePerfil" 
                                            placeholder="Perfil" 
                                            select-label=""
                                            selectedLabel=""
                                            :custom-label="customNamePerfil"
                                            :options="perfiles" 
                                            :searchable="true"
                                            @select="cambiarPerfil(record)"
                                            :loading="isLoadingPerfiles"
                                        >
                                        </multiselect>
                                        <!--<multi-list-select
                                            style=""
                                            :list="perfiles"
                                            option-value="idPerfil"
                                            option-text="nombrePerfil"
                                            id="mySelectId"
                                            name="mySelectName"
                                            :custom-text="codeAndNameAndDesc"
                                            :selected-items="items"
                                            placeholder="Seleccione un perfil"
                                            @select="onSelect"
                                            @searchchange="printSearchText"
                                        >
                                        </multi-list-select>-->
                                    </span>
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        <CButton type="submit" size="sm" class="float-right btn-class" :disabled="!usuariosCambiados.length>0 || isActualizandoPerfiles"  @click="asignarPerfiles">
                            <CIcon name="cil-check-circle"/> Asignar perfiles
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
            
        </CRow>
        
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import Multiselect from 'vue-multiselect'
    import axios from "axios"
    import CrearUsuario from "./componentes/CrearUsuario"

    import { MultiListSelect } from 'vue-search-select'
    
    export default {
        name: 'AsignacionEmpresas',
        components: {
            Multiselect,
            CrearUsuario,
            MultiListSelect 
        },
        data () {
            return {
                 options: [
                { value: '1', text: 'aa' + ' - ' + '1' },
                { value: '2', text: 'ab' + ' - ' + '2' },
                { value: '3', text: 'bc' + ' - ' + '3' },
                { value: '4', text: 'cd' + ' - ' + '4' },
                { value: '5', text: 'de' + ' - ' + '5' }
                ],
                searchText: '', // If value is falsy, reset searchText & searchItem
                items: [],
                lastSelectItem: {},
                //usuLogged = JSON.parse(this.$cookie.get('userLogginToken'))
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

                configurarPerfiles: false,
                nuevoPerfil: false,

                perfilNuevo:{
                    nombrePerfil: "",
                },

                nuevoUsuario: false,

                //mensajeAsignar;estadoMensajeAsignar;mostrarEstadoMensajeAsignar;tiempoAlertaAsignar

                mensajeAsignar: "",
                estadoMensajeAsignar: "warning",
                mostrarEstadoMensajeAsignar: false,
                tiempoAlertaAsignar: 10,

                estadoMensajeNuevoPerfil: "warning",
                mensajeNuevoPerfil: "",
                mostrarEstadoMensaje: false,
                tiempoAlertaCrear: 10,

                estadoMensajeEditarPerfil: "warning",
                mensajeEditarPerfil: "",
                mostrarEstadoMensajeEditar: false,
                tiempoAlertaEditar: 10,

                isLoadingUsuarios: false,
                isLoadingPerfiles: false,
                isLoadingTipoBD: false,
                isActualizandoPerfiles: false,

                crearPerfilesPorEmpresa: false,

                tipoBDSelected: null,
                tipoBD: [],
                isLoadingTipoBD: false,

                textoSinUsuarios: "Cargando Usuarios",

                tipoBD: [{"nombre":"Remuneraciones","valor":"REMU"}, {"nombre":"Contabilidad","valor":"CONTA"}],

                usuarios: null,
                usuariosCambiados: [],

                columnsPerfiles:[
                    { key: 'nombrePerfil',label: 'Nombre Perfil', _classes: 'text-left', _style:'width:40%;'},
                    { key: 'acciones',label: 'Acciones', _classes: 'text-center', _style:'width:30%;'},
                ],
                locale: {
                    filterConfirm: 'Filtrar',
                    filterReset: 'Limpiar',
                    emptyText: 'Seleccione sus opciones'
                },
                columnsUsuarios:[
                    { dataIndex: 'userName', key: 'idUsuario', title: 'Usuario', width: '20%', align: 'center'},
                    { key: 'rutUsuario', title: 'Rut', width: '20%', align: 'center', scopedSlots: { customRender: 'rutUsuario' }},
                    { key: 'nombreUsuario', title: 'Nombre', width: '20%', align: 'center', scopedSlots: { customRender: 'nombreUsuario' }},
                    { dataIndex: 'perfilAsignado', key: 'perfilAsignado', title: 'Perfil', width: '40%', scopedSlots: { customRender: 'perfil' }}
                ],

                perfiles: [],

                items: [],
                lastSelectItem: {}

            }
        },
        created: function(){},
        beforeMount() {

            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            this.getTipoBds()
            this.getPerfiles()
            this.getUsuarios()

        },
        methods: {
            printSearchText (searchText) {
                this.searchText = searchText
            },
            codeAndNameAndDesc (item) {
                return `${item.nombrePerfil}`
            },
            onSelect (items, lastSelectItem) {
                this.items = items
                this.lastSelectItem = lastSelectItem
            },
            // deselect option
            reset () {
                this.items = [] // reset
            },
            // select option from parent component
            selectFromParentComponent () {
                this.items = _.unionWith(this.items, [this.options[0]], _.isEqual)
            },

            getTipoBds: function(){
                this.isLoadingTipoBD = true

                var url = cons.port+"://" + cons.ipServer + "/get_tiposbds/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.tipoBD = result.data;

                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingTipoBD = false
                });
            },
            getUsuarios: function(){
                this.isLoadingUsuarios = true

                //let usuLogged = JSON.parse(this.$cookie.get('userLogginToken'))
                //var tokenLogin = usuLogged.tokenUsuario

                var url = cons.port+"://" + cons.ipServer + "/get_usuarios/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.usuarios = result.data;
                }, error => {
                    this.textoSinUsuarios = "Sin usuarios para mostrar"
                })
                .finally(() => {
                    this.isLoadingUsuarios = false
                });
            },

            getPerfiles: function(){
                this.isLoadingPerfiles = true

                var url = cons.port+"://" + cons.ipServer + "/get_perfiles/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.perfiles = result.data;
                }, error => {
                    let mensaje = `Problema al obtener empresas , ${error}`
                })
                .finally(() => {
                    this.isLoadingPerfiles = false
                });

            },

            cambiarPerfil: function(item){

                var encontrado = false
                this.usuariosCambiados.forEach(us =>{
                    if(us.idUsuario == item.idUsuario){
                        encontrado = true
                    }
                })
                if(!encontrado){
                    this.usuariosCambiados.push({
                        "idUsuario": item.idUsuario,
                        "idPerfil": 0
                    })
                }
            },

            asignarPerfiles: function(){

                this.isActualizandoPerfiles = true
                this.isLoadingUsuarios = true

                this.usuariosCambiados.forEach((us, i)=>{
                    for(var usuario in this.usuarios){
                        if(this.usuarios[usuario].idUsuario == us.idUsuario){
                            if(this.usuarios[usuario].perfilAsignado == null){
                                this.usuariosCambiados.splice(i,1)
                            } else {
                                us.idPerfil = this.usuarios[usuario].perfilAsignado.idPerfil
                            }
                        }
                    }
                })

                var url = cons.port+"://" + cons.ipServer + "/set_perfil_usuarios/"
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "cambiosUsuarios": this.usuariosCambiados
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    var mensaje = ""
                    if(result.data.usuariosActualizados.length>0){
                        mensaje += "Se han actualizado los usuarios "
                        var userActualizados = []
                        for(var usuario in result.data.usuariosActualizados){

                            var userEncontrado = this.usuarios.find(userf => userf.idUsuario == result.data.usuariosActualizados[usuario].idUsuario)

                            if(userEncontrado != null)
                                userActualizados.push(userEncontrado.userName)
                        }
                        mensaje += userActualizados.join(", ")
                        mensaje += " "
                    }
                    if(result.data.usuariosNoActualizados.length>0){
                        mensaje = "No se han podido actualizar los usuarios "
                        var userNoActualizados = []
                        for(var usuario in result.data.usuariosNoActualizados){
                            var userEncontrado = this.usuarios.find(userf => userf.idUsuario == result.data.usuariosNoActualizados[usuario].idUsuario)
                            if(userEncontrado != null)
                                userNoActualizados.push(userEncontrado.userName)
                        }
                        mensaje += userNoActualizados.join(", ")

                    }
                    this.$notification.success(mensaje, {  timer: 10, position:"bottomRight"});
                    /*this.mensajeAsignar= mensaje
                    this.estadoMensajeAsignar = "success"
                    this.mostrarEstadoMensajeAsignar = true
                    this.tiempoAlertaAsignar= 10*/
                    
                }, error => {
                    let mensaje = `Problema al actualizar usuarios , ${error}`
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                    /*this.mensajeAsignar= mensaje
                    this.estadoMensajeAsignar = "warning"
                    this.mostrarEstadoMensajeAsignar = true
                    this.tiempoAlertaAsignar= 10*/
                })
                .finally(() => {
                    this.isActualizandoPerfiles = false
                    this.isLoadingUsuarios = false
                });
                

            },
            agregarNuevoPerfil: function(){
                if(this.perfilNuevo.nombrePerfil.length > 0 && this.perfilNuevo.nombrePerfil != "" && this.perfilNuevo.nombrePerfil != null){
                    
                    let existePerfil = false
                    for(var per in this.perfiles){
                        
                        if(this.perfiles[per].nombrePerfil == this.perfilNuevo.nombrePerfil){
                            existePerfil = true
                            break
                        }
                    }
                    if(!existePerfil){
                        var url = cons.port+"://" + cons.ipServer + "/nuevo_perfil/"+this.perfilNuevo.nombrePerfil
                        
                        axios({
                            method: "GET", 
                            "url": url,
                            "headers": {
                                'Authorization': `${this.tokenLogin}`
                            }
                        }).then(result => {

                            //this.mensajeNuevoPerfil = result.data
                            //this.estadoMensajeNuevoPerfil = "success"
                            //this.tiempoAlertaCrear=5
                            //this.mostrarEstadoMensaje = true
                            this.getPerfiles()
                            this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                        }, error => {
                            //let mensaje = `Problema al crear perfil , ${error}`
                            //this.mensajeNuevoPerfil = mensaje
                            //this.estadoMensajeNuevoPerfil = "danger"
                            //this.tiempoAlertaCrear=5
                            //this.mostrarEstadoMensaje = true
                            this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                        })
                        .finally(() => {
                            
                        });
                    } else{
                        this.mensajeNuevoPerfil = `ya existe el perfil ${this.perfilNuevo.nombrePerfil}`
                        this.$notification.error(mensajeNuevoPerfil, {  timer: 10, position:"bottomRight"});
                        /*this.estadoMensajeNuevoPerfil = "danger"
                        this.tiempoAlertaCrear=5
                        this.mostrarEstadoMensaje = true*/
                    }
                } else{
                    this.mensajeNuevoPerfil = "Ingrese nombre del perfil"
                    this.$notification.error(mensajeNuevoPerfil, {  timer: 10, position:"bottomRight"});
                    /*this.estadoMensajeNuevoPerfil = "danger"
                    this.tiempoAlertaCrear=5
                    this.mostrarEstadoMensaje = true*/
                }

            },
            crearPerfilesPorEmpresas: function(){
                var url = cons.port+"://" + cons.ipServer + "/crear_por_empresa/"+this.tipoBDSelected.valor
                        
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.getPerfiles()
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    
                });
            },
            actualizarPerfil(perfil){

                if(perfil.nombrePerfil.length > 0 && perfil.nombrePerfil != "" && perfil.nombrePerfil != null){

                    let existePerfil = false
                    for(var per in this.perfiles){
                        if(this.perfiles[per].nombrePerfil == perfil.nombrePerfil)
                            existePerfil = true
                            break
                    }
                    if(!existePerfil){
                        var url = cons.port+"://" + cons.ipServer + "/editar_perfil/"+perfil.idPerfil+"/"+perfil.nombrePerfil
                                
                        axios({
                            method: "GET", 
                            "url": url,
                            "headers": {
                                'Authorization': `${this.tokenLogin}`
                            }
                        }).then(result => {

                            this.estadoMensajeEditarPerfil = "success"
                            this.mensajeEditarPerfil = result.data
                            this.tiempoAlertaEditar = 10
                            this.mostrarEstadoMensajeEditar = true
                        
                            this.getPerfiles()

                        }, error => {
                            let mensaje = `Problema al actualizar perfil , ${error}`
                            this.estadoMensajeEditarPerfil = "danger"
                            this.mensajeEditarPerfil = mensaje
                            this.tiempoAlertaEditar = 10
                            this.mostrarEstadoMensajeEditar = true
                        })
                        .finally(() => {
                            
                        });
                    } else {
                        this.estadoMensajeEditarPerfil = "danger"
                        this.mensajeEditarPerfil =  `ya existe el perfil ${this.perfilNuevo.nombrePerfil}`
                        this.tiempoAlertaEditar = 10
                        this.mostrarEstadoMensajeEditar = true
                    }
                } else{
                    this.estadoMensajeEditarPerfil = "danger"
                    this.mensajeEditarPerfil = "Ingrese un nombre de perfil"
                    this.tiempoAlertaEditar = 10
                    this.mostrarEstadoMensajeEditar = true
                }
            },
            eliminarPerfil: function(perfil){
                var url = cons.port+"://" + cons.ipServer + "/eliminar_perfil/"+perfil.idPerfil
                                
                axios({
                    method: "GET",
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.estadoMensajeEditarPerfil = "success"
                    this.mensajeEditarPerfil = result.data
                    this.tiempoAlertaEditar = 10
                    this.mostrarEstadoMensajeEditar = true
                
                    this.getPerfiles()

                }, error => {
                    let mensaje = `Problema al eliminar perfil , ${error}`
                    this.estadoMensajeEditarPerfil = "danger"
                    this.mensajeEditarPerfil = mensaje
                    this.tiempoAlertaEditar = 10
                    this.mostrarEstadoMensajeEditar = true
                })
                .finally(() => {
                    
                });
            },

            customNamePerfil(perfil){
                return `${perfil.nombrePerfil}`
            },
            customNameTipoBD(tipoBD){
                return `${tipoBD.nombre}`
            },
        }
        
    }
</script>
<style scoped>
.multiselect, .multiselect__input, .multiselect__single  {
    font-size: 9px;
}

</style>